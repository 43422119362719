<template>
  <div class="create-contact">
    <ContactAppbar />
    <div class="ma-4">
      <UploadAvatarContact
        ref="avatarUploader"
        :maxWidth="maxWidth"
        :maxHeight="maxHeight"
        :showUploadBtn="true"
      />
      <v-divider class="contact-details__divider"></v-divider>
      <ContactForm @upload-image="uploadImage" />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Contact
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import ContactAppbar from '@/views/Home/Hub/Contacts/components/ContactAppbar'
import ContactForm from '@/views/Home/Hub/Contacts/components/ContactForm'
import UploadAvatarContact from '@/views/Home/Hub/Contacts/components/UploadAvatarContact'
import UPLOAD_IMAGE_SIZE from '@/utils/enums/UploadImageSize'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  components: {
    ContactAppbar,
    ContactForm,
    UploadAvatarContact,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),

    hasDeletePermission() {
      return validatePermissions([PERMISSION.CONTACTS_DELETE], this.permissions)
    },

    maxWidth() {
      return UPLOAD_IMAGE_SIZE.CONTACT_IMAGE.width
    },

    maxHeight() {
      return UPLOAD_IMAGE_SIZE.CONTACT_IMAGE.height
    },
  },

  methods: {
    ...mapActions({
      changeAvatar: 'contact/changeAvatar',
    }),

    uploadImage(id) {
      let data = new FormData()
      data.append('id', id)
      this.$refs.avatarUploader.getAvatarUpload(data)

      if (data.get('avatar') && data.get('avatar_url')) {
        this.changeAvatar(data)
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.showSnackbar('Contact successfully created!')
            this.$router.push({
              name: 'hub.contact-directory',
            })
          })
      } else {
        this.showSnackbar('Contact successfully created!')
        this.$router.push({
          name: 'hub.contact-directory',
        })
      }
    },
  },
}
</script>
<style lang="scss">
.create-contact {
  //
}
</style>
